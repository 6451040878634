import Swiper from 'swiper';
import { Autoplay } from 'swiper/modules'
import 'swiper/css';




// init Swiper:
const storesSlider = new Swiper('.places', {
    modules: [Autoplay],
    slidesPerView: 2.5,
    spaceBetween: 60,
    speed: 400,
    autoplay: {
        delay: 2000,
    },
    loop: true,
    breakpoints: {
        768: {
            slidesPerView: 4,
            spaceBetween: 120
        },
        1024: {
            slidesPerView: 5,
            spaceBetween: 150
        },
        1278: {
            slidesPerView: 5.8,
            spaceBetween: 120
        },
        1600: {
            slidesPerView: 6,
            spaceBetween: 150
        },
        1777: {
            slidesPerView: 6,
            spaceBetween: 200
        }
    }
});


const anotherProductsSlider = new Swiper('.products_list', {
    modules: [Autoplay],
    slidesPerView: 2,
    spaceBetween: 32,
    speed: 400,
    // loop: true,
    // autoplay: {
    //     delay: 2000,
    // },
    breakpoints: {
        1024: {
            slidesPerView: 3,
        },
        1278: {
            slidesPerView: 3.5
        }
    }
});