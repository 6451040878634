import './bootstrap';
import './sliders';
import anime from 'animejs/lib/anime.es.js';


import.meta.glob([
    '../images/**',
]);

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);
function setWindowHeight(){
    var windowHeight = window.innerHeight;
    document.body.style.height = windowHeight + "px";
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}
window.addEventListener("resize",setWindowHeight,false);



function collapse_filter_section(e) {
    let section = e.target.closest('.acc_item');
    let options_list = section.querySelector('.acc_description');

    let height = options_list.clientHeight;
    if (options_list.classList.contains('closed')) {
        options_list.style.height = 'auto';
        height = options_list.clientHeight;
        options_list.style.height = 0;
    }
    anime({
        targets: options_list,
        height: (!options_list.classList.contains('closed')) ? [height, 0] : [0, height],
        opacity: (!options_list.classList.contains('closed')) ? [1, 0] : [0, 1],
        easing: 'easeInOutQuad',
        duration: 300,
        complete: function (anim) {
            anim.animatables[0].target.classList.toggle('closed');
        }
    })
    anime({
        targets: section.querySelector('svg'),
        rotate: (!options_list.classList.contains('closed')) ? [0, 180] : [180, 0],
        easing: 'easeInOutQuad',
        duration: 300,
    })

}
window.collapse_filter_section = (e) => collapse_filter_section(e);
